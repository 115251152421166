import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Avatar,
    Box,
    Button,
    Flex,
    IconButton,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Select,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tooltip,
    Tr,
    useColorModeValue,
    useDisclosure,
    Icon
} from '@chakra-ui/react'

import {
    ArrowRightIcon,
    ArrowLeftIcon,
    ChevronRightIcon,
    ChevronLeftIcon, RepeatIcon,
    CheckIcon, CloseIcon,
    DeleteIcon
} from "@chakra-ui/icons"

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    Column,
    Table as ReactTable,
    PaginationState,
    ColumnDef,
    OnChangeFn
} from '@tanstack/react-table'
// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import {AndroidLogo, AppleLogo, DocumentIcon, SettingsIcon, StatsIcon, WindowsLogo} from 'components/icons/Icons';
import * as React from 'react';
import IUsers from "../../../../types/users";
import {useEffect} from "react";
import UserService from "../../../../services/UserService";
import Germany from 'assets/img/flags/germany.png';
import Uk from 'assets/img/flags/united-kingdom.png';
import IPageable from "../../../../types/pageable";
import useDebounce from "../useDebounce";

import {SingleDatepicker} from "chakra-dayzed-datepicker";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Spinner} from '@chakra-ui/react'
import {AbsoluteCenter} from '@chakra-ui/react'
import {AxiosError} from "axios";
import Moment from 'moment';

const columnHelper = createColumnHelper<IUsers>()

export default function ComplexTable(props: { tableData: any }) {
    const [tableData, setUsers] = React.useState<Array<IUsers>>([])
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [pc, setPageCount] = React.useState<number>()
    const [pageable, setPageable] = React.useState<IPageable>({
        pageNumber: 1,
        pageSize: 10
    })
    const [totalElements, setTotalElements] = React.useState(0)
    const [numberOfElements, setNumberOfElements] = React.useState(0)

    const [search, setSearch] = React.useState('');
    const [debouncedSearch, setDebouncedSearch] = useDebounce('', 300)

    const textColor = useColorModeValue('secondaryGray.900', 'white')
    const iconColor = useColorModeValue('secondaryGray.500', 'white')
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100')

    const [loading, setLoading] = React.useState(false);
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();

    const [error, setError] = React.useState(null)

    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef()

    let defaultData = tableData

    const fetchData = async (page: number, size: number, query: string, period: Date[]) => {
        setLoading(true);
        UserService.search(page, size, query, period).then((res) => {
            if (res.status >= 200 && res.status < 300) {
                setData(res.data.content)
                setPageable(res.data.pageable)
                setPageCount(res.data.totalPages)
                setTotalElements(res.data.totalElements)
                setNumberOfElements(res.data.numberOfElements)
                setError(null)
            } else {
                throw new Error("Server can't be reached!")
            }
        }).catch((err: AxiosError) => {
            setError(`${err.message}. `)
        })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData(0, 10, "", null)
    }, [])

    useEffect(() => {
        if (!search) {
            return;
        }
        setDebouncedSearch(search)
    }, [search]);

    useEffect(() => {
        if (!debouncedSearch) return;
        fetchData(0, 10, search, [startDate, endDate])
    }, [debouncedSearch]);

    useEffect(() => {
        fetchData(0, 10, search, [startDate, endDate])
    }, [startDate, endDate])

    function getElementsCountTxt(): string {
        let message = `${pageable.pageNumber * pageable.pageSize + 1} to `
        if (pageable.pageNumber * pageable.pageSize + pageable.pageSize > totalElements) {
            message += `${totalElements} of ${totalElements} users`
        } else {
            message += `${pageable.pageNumber * pageable.pageSize + pageable.pageSize} of ${totalElements} users`
        }
        return message
    }

    const getErrorView = () => {
        return (
            <div>
                Something went wrong. {error}
                <button>
                    Try again
                </button>
            </div>
        )
    }

    const deleteUser = () => {

    };

    const columns = [
        /*columnHelper.accessor('id', {
            id: 'id',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    ID
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),*/
        columnHelper.accessor('username', {
            id: 'username',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    USERNAME
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('firstName', {
            id: 'firstName',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    FIRST NAME
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('lastName', {
            id: 'lastName',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    LAST NAME
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    EMAIL
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('birthday', {
            id: 'birthday',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    BIRTHDAY
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('language', {
            id: 'language',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    LANGUAGE
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    {info.getValue() === "en" ? (<Avatar src={Uk} size={"xs"}/>) :
                        <Avatar src={Germany} size={"xs"}/>}
                </Flex>
            )
        }),
        columnHelper.accessor('gender', {
            id: 'gender',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    GENDER
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('optInMarketingEmails', {
            id: 'optInMarketingEmails',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    NEWSLETTER
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue() ? (<Icon as={CheckIcon} boxSize={4}/>) :
                            <Icon as={CloseIcon} boxSize={4}/>}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('accountType', {
            id: 'accountType',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    PREMIUM
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('pushMessages', {
            id: 'pushMessages',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    PUSH MSG
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {info.getValue()}
                    </Text>
                </Flex>
            )
        }),
        columnHelper.accessor('created.timestamp', {
            id: 'registrationDate',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    REGISTERED
                </Text>
            ),
            cell: (info: any) => (
                <Flex align='center'>
                    <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {Moment(info.getValue()).format('YYYY-MM-DD HH:mm:ss')}
                    </Text>
                </Flex>
            )
        })/*,
        columnHelper.accessor(null, {
            id: 'actions',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'>
                    ACTIONS
                </Text>
            ),
            cell: (info: any) => (
                <Tooltip label="Delete user">
                    <Flex align='center'>
                        <IconButton aria-label='Search database' icon={<DeleteIcon/>}
                                    onClick={onOpen}/>
                    </Flex>
                </Tooltip>
            )
        })*/
    ]
    const [data, setData] = React.useState(() => [...defaultData])
    const table = useReactTable({
            data,
            columns,
            state: {
                sorting,
                pagination: {pageIndex: pageable.pageNumber, pageSize: pageable.pageSize}
            },
            onSortingChange: setSorting,
            manualPagination: true,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            debugTable: true,
            pageCount: pc,
            filterFns: undefined
        }
    )
    return (
        <Card flexDirection='column' w='200%' px='0px' overflowX={{sm: 'scroll', lg: 'hidden'}}>
            <Flex mb={5} ml={5}>
                <input value={search} onChange={e => setSearch(e.target.value.trim())}
                       style={{borderColor: 'lightgray', width: '25%', borderWidth: '1px', borderRadius: '5px'}}
                       placeholder={'Search users...'}/>
                <Tooltip label="Clear filters">
                    <IconButton
                        onClick={() => {
                            setSearch("")
                            setStartDate(null) //TODO: null here -> calendar has no dates, need to be fixed to remove this bug
                            setEndDate(null)
                            fetchData(0, table.getState().pagination.pageSize, "", null)
                        }}
                        icon={<RepeatIcon h={5} w={5}/>}
                        mr={4}
                        aria-label={"Test"}
                    >
                    </IconButton>
                </Tooltip>
            </Flex>
            <Flex mb={5} ml={5}>
                {/*<SingleDatepicker
                    name="start-date-input"
                    date={startDate}
                    onDateChange={setStartDate}
                />
                <SingleDatepicker
                    name="end-date-input"
                    date={endDate}
                    onDateChange={setEndDate}
                />*/}
            </Flex>

            <Flex ml={5}>Showing {getElementsCountTxt()}</Flex>

            <Box>
                <Table variant='simple' color='gray.500' mb='24px' mt="12px">
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <Th
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            pe='10px'
                                            borderColor={borderColor}
                                            cursor='pointer'
                                            onClick={header.column.getToggleSortingHandler()}>
                                            <Flex
                                                justifyContent='space-between'
                                                align='center'
                                                fontSize={{sm: '10px', lg: '12px'}}
                                                color='gray.400'>
                                                {flexRender(header.column.columnDef.header, header.getContext())}{{
                                                asc: '',
                                                desc: '',
                                            }[header.column.getIsSorted() as string] ?? null}
                                            </Flex>
                                        </Th>
                                    )
                                })}
                            </Tr>
                        ))}
                    </Thead>

                    <AbsoluteCenter hidden={!loading}>
                        <Flex justifyContent="space-between" m={4} alignItems="center">
                            <Spinner size='xl' hidden={!loading} display={"flex"} justifyContent={"center"}
                                     alignItems={"center"}/>
                        </Flex>
                    </AbsoluteCenter>

                    <AbsoluteCenter hidden={!error}>
                        <ul>
                            {getErrorView()}
                        </ul>
                    </AbsoluteCenter>

                    <Tbody hidden={loading}>
                        {table.getRowModel().rows.slice(0, pageable.pageSize).map((row) => {
                            return (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <Td
                                                key={cell.id}
                                                fontSize={{sm: '14px'}}
                                                minW={{sm: '150px', md: '200px', lg: 'auto'}}
                                                borderColor='transparent'>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </Td>
                                        )
                                    })}
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>

                <Flex justifyContent="space-between" m={4} alignItems="center">
                    <Flex>
                        <Tooltip label="First Page">
                            <IconButton
                                onClick={() => {
                                    table.setPageIndex(0)
                                    fetchData(0, table.getState().pagination.pageSize, search, [startDate, endDate])
                                }}
                                isDisabled={!table.getCanPreviousPage()}
                                icon={<ArrowLeftIcon h={3} w={3}/>}
                                mr={4}
                                aria-label={"Test"}
                            >
                            </IconButton>
                        </Tooltip>
                        <Tooltip label="Previous Page">
                            <IconButton
                                aria-label={""}
                                onClick={() => {
                                    table.previousPage()
                                    table.setPageIndex(table.getState().pagination.pageIndex - 1)
                                    fetchData(table.getState().pagination.pageIndex - 1, table.getState().pagination.pageSize, search, [startDate, endDate])
                                }}
                                isDisabled={!table.getCanPreviousPage()}
                                icon={<ChevronLeftIcon h={6} w={6}/>}
                            />
                        </Tooltip>
                    </Flex>

                    <Flex alignItems="center">
                        <Text mr={8}>
                            Page{" "}
                            <Text fontWeight="bold" as="span">
                                {table.getState().pagination.pageIndex + 1}
                            </Text>{" "}
                            of{" "}
                            <Text fontWeight="bold" as="span">
                                {table.getPageCount()}
                            </Text>
                        </Text>
                        <Text>Go to page:</Text>{" "}
                        <NumberInput
                            ml={2}
                            mr={8}
                            w={28}
                            min={1}
                            max={table.getPageCount()}
                            onChange={e => {
                                const pageNumber = Number(e) ? Number(e) - 1 : 0
                                table.setPageIndex(pageNumber)
                                fetchData(pageNumber, table.getState().pagination.pageSize, search, [startDate, endDate])
                            }}
                            defaultValue={1}
                        >
                            <NumberInputField/>
                            <NumberInputStepper>
                                <NumberIncrementStepper/>
                                <NumberDecrementStepper/>
                            </NumberInputStepper>
                        </NumberInput>
                        <Select
                            w={32}
                            /*value={table.getState().pagination.pageSize}*/
                            value={pageable.pageSize}
                            onChange={(e) => {
                                setPageable({pageSize: Number(e.target.value), pageNumber: pageable.pageNumber})
                                fetchData(table.getState().pagination.pageIndex, Number(e.target.value), search, [startDate, endDate])
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </Select>
                    </Flex>

                    <Flex>
                        <Tooltip label="Next Page">
                            <IconButton
                                aria-label={""}
                                onClick={() => {
                                    //table.nextPage
                                    table.setPageIndex(table.getState().pagination.pageIndex + 1)
                                    fetchData(table.getState().pagination.pageIndex + 1, table.getState().pagination.pageSize, search, [startDate, endDate])
                                }}
                                isDisabled={!table.getCanNextPage()}
                                icon={<ChevronRightIcon h={6} w={6}/>}
                            />
                        </Tooltip>
                        <Tooltip label="Last Page">
                            <IconButton
                                aria-label={""}
                                onClick={() => {
                                    table.setPageIndex(table.getPageCount() - 1)
                                    fetchData(table.getPageCount() - 1, table.getState().pagination.pageSize, search, [startDate, endDate])
                                }}
                                isDisabled={!table.getCanNextPage()}
                                icon={<ArrowRightIcon h={3} w={3}/>}
                                ml={4}
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
            </Box>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete user
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure to delete user?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={deleteUser} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Card>
    )
}
