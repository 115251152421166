import UserService from "services/UserService";

type RowObj = {
    name: string;
    email: string;
    type: string;
    accountType: string;
    tech: string[];
    date: string;
    progress: number;
};

const tableDataComplex = {
    list: () =>
        UserService.search(0, 10, "", [])
};


export default tableDataComplex;
