import {
    getAuth
} from 'firebase/auth';

const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    return user?.stsTokenManager.accessToken;
}

const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.stsTokenManager.refreshToken;
}

const refreshToken = async () => {
    let user = getAuth().currentUser
    return user.getIdToken(true)
}

const updateLocalAccessToken = (token: string) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.stsTokenManager.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
}

const TokenService = {
    getLocalAccessToken,
    getLocalRefreshToken,
    updateLocalAccessToken,
    refreshToken
};
export default TokenService;
