import axios from "axios";
import TokenService from "./services/TokenService";

const instance = axios.create({
    baseURL: process.env.REACT_APP_ADMIN_BACKEND_URL + "/api/admin",
    headers: {
        "Content-type": "application/vnd.mats.app-1.0+json",
        "Accept": "application/vnd.mats.app-1.0+json"
    }
});

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();

        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => { //here we should get new token
        const originalConfig = err.config;
        if (originalConfig.url !== "/auth/signin" && err.response) {

            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    let accessToken = await TokenService.refreshToken()
                    TokenService.updateLocalAccessToken(accessToken);

                    return instance(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    }
);

export default instance;
