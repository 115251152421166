import http from "../http-common";
import IUserPage from "../types/userPage";
import FilterPeriod from "../types/period";

const search = (page: number, size: number, query: string, dates: Date[]) => {
    let period: FilterPeriod = null
    if (dates != null && (dates[0] != null || dates[1] != null)) {
        period = new FilterPeriod(dates[0], dates[1])
    }

    return http.post<IUserPage>(`/user/search?page=${page}&size=${size}&sort=email,asc`, {
        "query": query,
        "period": period
    }, {})
};

const UserService = {
    search
};

export default UserService;