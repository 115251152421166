// Chakra imports
import {Avatar, Flex, useColorModeValue} from '@chakra-ui/react';

// Custom components
import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';
import Germany from "../../../assets/img/flags/germany.png";
import Mats from "../../../assets/img/auth/mats.png";
import * as React from "react";

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Avatar src={Mats} size={"2xl"}/>
		</Flex>
	);
}

export default SidebarBrand;
